.guide-step {
  cursor: pointer;
  box-sizing: border-box;
  font-size: 1.1rem;
  padding: 1rem;
}
.guide-step:hover {
  background: rgba(241, 242, 244, 1);
}
.guide-button-div {
  width: 100%;
}
.guide-button {
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.bodyMd {
  font-size: 1.3rem;
}
.width-image {
  width: 90px;
}
